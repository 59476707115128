import Loadable from 'react-loadable';
import React from 'react';
import 'graphiql/graphiql.css';

export const AsyncVisualizerPage = Loadable({
  loader: () => import(/* webpackChunkName: "visualizer" */ './Visualizer').then((res) => res.Visualizer),
  loading() {
    return <>Загрузка</>;
  },
  delay: 300,
});
