import './global-initializers';
import './async-imports';

// declare const Intercom: any;

import * as React from 'react';
import * as ReactDom from 'react-dom';

import { Router } from 'react-router-dom';

import { createBrowserHistory } from 'history';
import { RootPage } from './components/root/RootPage';

import { GlobalStyles, FontStyles } from '@styles/global/global-styles';

const history = createBrowserHistory();

ReactDom.render(
  <Router history={history}>
    <>
      <GlobalStyles />
      <FontStyles />
      <RootPage />
    </>
  </Router>,
  document.getElementById('root'),
);
