import { createGlobalStyle } from '@styles/styled-helpers';

/* Базовые стили для страницы */
export const GlobalStyles = createGlobalStyle`
    body {
        overflow: hidden;
    }
`;

/* Шрифты */
export const FontStyles = createGlobalStyle`
    @font-face {
        font-family: 'MuseoSansCyrl';
        font-style: normal;
        font-weight: 100;
        src: url(/assets/fonts/MuseoSansCyrl-100.otf) format('opentype');
    }

    @font-face {
        font-family: MuseoSansCyrl;
        font-style: normal;
        font-weight: 300;
        src: url(/assets/fonts/MuseoSansCyrl-300.otf) format('opentype');
    }

    @font-face {
        font-family: 'MuseoSansCyrl';
        font-style: normal;
        font-weight: 500;
        src: url(/assets/fonts/MuseoSansCyrl-500.otf) format('opentype');
    }

    @font-face {
        font-family: 'MuseoSansCyrl';
        font-style: normal;
        font-weight: 700;
        src: url(/assets/fonts/MuseoSansCyrl-700.otf) format('opentype');
    }

    @font-face {
        font-family: 'MuseoSansCyrl';
        font-style: normal;
        font-weight: 900;
        src: url(/assets/fonts/MuseoSansCyrl-900.otf) format('otf');
    }
`;
