import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { AsyncPlaygroundPage } from '../sections/playground/async';
import { AsyncVisualizerPage } from '../sections/visualizer/async';

export const RootPage = () => {
  return (
    <Switch>
      <Route path="/playground" component={AsyncPlaygroundPage} />
      <Route path="/visualizer" component={AsyncVisualizerPage} />
      <Redirect to="/playground" />
    </Switch>
  );
};
