import Loadable from 'react-loadable';
import React from 'react';
import 'graphiql/graphiql.css';

export const AsyncPlaygroundPage = Loadable({
  loader: () => import(/* webpackChunkName: "playground" */ './Playground').then((res) => res.Playground),
  loading() {
    return <>Загрузка</>;
  },
  delay: 300,
});
